table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.progress-cell {
    position: relative;
    padding-right: 0px;
    padding-left: 0px;
    border-left: 1px solid #ddd;
}
