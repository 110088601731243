// Intro Section

.intro-item{

	i{
		font-size:60px;
		line-height: 60px;
	}

}

.color-one{
	color: $primary-color;
}
.color-two{
	color: #00d747;
}
.color-three{
	color:#9262ff;
}
.color-four{
	color:#088ed3;
}

.section.intro {
	background-color: #353535;
	padding: 110px 0;

	.section-title {
		margin-bottom: 36px;
	}

	.content-title {
		font-family: $primary-font;
		font-size: 36px;
		line-height: 1.75;
		font-weight: bold;
		text-align: center;
		color: $light;
	}

	.content-body {
		font-family: $primary-font;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.63;
		text-align: center;
		color: $light;
	}
}
