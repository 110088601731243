
/*=== MEDIA QUERY ===*/

@include mobile-xs{
   .header-top .header-top-info a {
      margin-left: 0px;
      display: block;
  }

  .navbar-toggler{
    color: $black;
  }
  .slider .block h1 {
      font-size: 28px;
      line-height: 40px;
  }

  .slider{
    padding: 126px 0 151px 0;
  }

  .content-title {
      font-size: 28px;
      line-height: 46px;
  }

  .bg-about{
    display: none;
  }

  .p-5{
    padding: 2rem !important;
  }
  h2, .h2 {
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 36px;
  }

  .testimonial-item .testimonial-item-content {
      padding-left: 0px;
      padding-top: 30px;
  }

  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
      margin-left: 0px;
  }

  .text-lg {
      font-size: 3rem;
  }

  .header-top .header-top-info {
      padding-bottom: 15px;
  }
  .blog-item-meta span{
    margin: 6px 0px;
  }

  .widget {
      margin-bottom: 30px;
      padding-bottom: 0px;
  }

  .dropdown-menu{
    display: none;
    width: 100%;
    text-align: center;
  }
}

@include mobile{
  .header-top .header-top-info a {
      margin-left: 0px;
  }

  .navbar-toggler{
    color: $black;
  }
  .slider .block h1 {
      font-size: 38px;
      line-height: 50px;
  }

  .slider{
    padding: 126px 0 151px 0;
    // display: none;
    margin-top: 0;
    word-wrap: break-word;
  }

  .content-title {
      font-size: 28px;
      line-height: 46px;
  }

  .bg-about{
    display: none;
  }

  .p-5{
    padding: 2rem !important;
  }
  h2, .h2 {
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 36px;
  }

  .testimonial-item .testimonial-item-content {
      padding-left: 0px;
      padding-top: 30px;
  }

  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
      margin-left: 0px;
  }
  .blog-item-meta span{
    display: block;
    margin: 6px 0px;
  }
  .widget {
      margin-bottom: 30px;
      padding-bottom: 0px;
  }

  .dropdown-menu{
    display: none;
    width: 100%;
    text-align: center;
  }
}


@include tablet{
  .navbar-toggler{
    color: $black;
  }
  .bg-about{
    display: none;
  }
  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
      margin-left: 0px;
  }
  .slider .block h1 {
      font-size: 56px;
      line-height: 70px;
  }
  .blog-item-meta span{
    display: block;
    margin: 6px 0px;
  }
  .widget {
      margin-bottom: 30px;
      padding-bottom: 0px;
  }
  .dropdown-menu{
    display: none;
    width: 100%;
    text-align: center;
  }
}

@include desktop{
  .section.milestone {
    .row {
      margin-bottom: 0;
    }

    .item {
      margin-bottom: 45px;
    }
  }

  .section.press .item-box .press-item img {
    height: 250px;
  }
}


@include large-desktop{
  #navbar {
    max-height: initial;
  }

  .wrap-bg-element {
    height: calc(100vh - 100px);
  }

  .page-title.parallax {
    height: 100%;
    margin-top: initial;

    // background-attachment: initial;
  }

  .main-logo {
    display: block;
  }

  .white-logo {
    display: none;
  }
}
