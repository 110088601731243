// Milestone Section

.section.milestone {
    background-color: #2B2B2B;
    background-size: cover;
	padding: 64px 0 110px;

    .content-title {
        font-family: $primary-font;
        font-size: 36px;
        font-weight: bold;
        line-height: 1.75;
        text-align: center;
        color: $light;

        margin-bottom: 64px;
    }

    .box {
        margin-bottom: 45px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .item {
        padding: 22px;
        margin: 10px;
        border-radius: 8px;
        border: solid 1px #979797;
        // background-color: rgba(45, 45, 45, 0.7);
        background-color: #2B2B2B;

        -webkit-box-shadow: 15px -20px 0px 7px $black;
        box-shadow: 15px -20px 0px 7px $black;

        h3, p, * {
            color: $light;
            font-size: 14px;
        }

        h3 {
            margin: 0;
            margin-bottom: 7px;
        }

        .description {
            margin-bottom: 10px;

            p {
                line-height: 1.3;
                margin: 0;
            }
        }

        .date {
            font-weight: bold;
        }

        img {
            margin-bottom: 16px;
            max-width: 100%;
        }
    }
}
