.slider {
  margin-top: -100px;
  // padding: 200px 0;
  position: relative;
  z-index: -1;

  display: flex;
  align-items: center;
  height: 100vh;

  .dim {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $black;
    z-index: 2;
    top: 0;
    left: 0;
  }

  video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 3;
  }

  @extend .overly;
  .block{
    h1{
      font-size: 80px;
      line-height: normal;
      font-weight: 800;
      color: $light;
      margin-bottom: 0;
    }
    p{
      margin-bottom:48px;
      color:#b9b9b9;
      line-height: normal;
      font-weight: 300;
    }

    span{
      letter-spacing: 1px;
      font-size: 48px;
      font-weight: 300;
    }
  }
}
