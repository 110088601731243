.footer {
  padding-bottom: 0;
  background-color: #333333;

  &.section {
    padding-top: 75px;
  }

  .copyright {
    a {
      font-weight: 600;
    }
  }

  h3, h4, h6, a, p {
    color: $light;
  }

  .logo img {
    height: 30px;
  }

  .social a {
    &:hover {
      color: $primary-color;
    }
  }

  .btn-member {
    background-color: transparent;
    border-radius: 40px;
    font-size: 10px;

    &:hover {
      background-color: $light;
      color: $black;
    }
  }
}

.lh-35 {
  line-height: 35px;
}

.logo {
  color: $black;
  font-weight: 600;
  letter-spacing: 1px;

  span {
    color: $primary-color;
  }
}

.sub-form {
  position: relative;
  .form-control {
    border: 1px solid rgba(0, 0, 0, 0.06);
    background: $secondary-color;
  }
}

.footer-btm {
  background-color: #181818;
  color: $light;
  letter-spacing: 0.62px;

  font-size: 14px;
  font-family: $primary-font;
  font-weight: bold;
}

.footer-socials {
  li a {
    margin-left: 15px;
  }
}
