// Press Section

.section.press {
	padding: 85px 0;


	.section-title {
		margin-bottom: 32px;
	}

	.content-title {
		font-family: $primary-font;
		font-size: 36px;
		line-height: 1.75;
		font-weight: bold;
		text-align: center;
		color: $black;
	}

	.content-body {
		font-family: $primary-font;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.63;
		text-align: center;
		color: #333333;
        margin: 0;
	}

    .category-url {
        text-align: center;

        a {
            font-family: $primary-font;
            font-size: 18px;
            font-weight: 300;
            line-height: 1.67;
            color: #6B6768;

            i {
                background-color: #6B6768;
                padding: 10px;
                color: $light;
                border-radius: 50%;
                margin-left: 10px;
                vertical-align: middle;
            }

            &:hover {
                color: $primary-color;

                i {
                    background-color: $primary-color;
                }
            }
        }
    }

    .item-box {
        margin: 55px 0 60px;

        // .col-12 {
        //     padding: 0 24px;
        // }

        .press-item {
            height: 100%;
            border-radius: 8px;
            box-shadow: 0 4px 30px 10px rgba(155, 155, 155, 0.25);

            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                object-position: center;
            }

            .press-content {
                padding: 32px;
                text-align: left;
                word-wrap: break-word;

                .release-date {
                    color: #333333;
                    font-family: $primary-font;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 2.5;
                }

                p {
                    font-size: 16px;
                    font-weight: 300;
                    color: #333333;
                    line-height: 1.63;
                }

                .btn-solid-border {
                    height: 40px;
                    padding: 10px 16px 12px;
                    border: solid 1px #212121;
                    margin-bottom: 40px;

                    font-family: $primary-font;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 1.4px;
                    text-align: center;
                    color: $black;

                    &:hover {
                        color: $light;
                        border-color: transparent;
                    }
                }
            }
        }
    }
}
